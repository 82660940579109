<template>
  <div>
    <div class="intro-container">
      <swiper
        v-if="bildergalerie.length"
        :slides-per-view="'auto'"
        :loop="true"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(bild, index) in bildergalerie"
          :key="bild.id"
          class="slide"
        >
          <div class="image-text-container">
            <img :src="bild.bild.large" :alt="bild.bild.alt">
            <div class="pagination">
              {{ index + 1 }} / {{ bildergalerie.length }}
            </div>
            <div class="caption" v-html="bild.bildtext" />
          </div>
        </swiper-slide>
        <div class="nextSlide" @click="swiper.slideNext()" />
        <div class="prevSlide" @click="swiper.slidePrev()" />
      </swiper>
      <ScrollIndicator />
      <BackButton :link="project.link" :slug="project.slug" />
    </div>
    <section class="content-section bg-white row-24 mx-0">
      <div class="content-headline col-22 offset-1 md:col-16 md:offset-6 lg:col-14 lg:offset-5">
        <h2 v-html="project.artikelheadline" />
      </div>
      <div class="content-container row-24 mx-0">
        <div
          class="col-22 offset-1 md:col-14 md:offset-7 lg:col-10"
          v-html="project.artikelinhalt"
        />
      </div>
    </section>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {
  Autoplay, A11y, Navigation,
} from 'swiper';

import ScrollIndicator from '../parts/ScrollIndicator.vue';
import BackButton from '../parts/BackButton.vue';

SwiperCore.use([Autoplay, A11y, Navigation]);

export default {
  components: {
    ScrollIndicator,
    BackButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      project: this.payload,
      swiper: {},
    };
  },
  computed: {
    bildergalerie() {
      return this.project.bildergalerie;
    },
  },
  methods: {
    onSwiper(swiper) { this.swiper = swiper; },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.intro-container {
  overflow: hidden;
}

// .swiper-container {
//   color: var(--color-white);
//   height: 100%;
//   width: 100%;
//   overflow: visible;
//   .nextSlide, .prevSlide {
//     display: none;
//     width: 50px;
//     height: 50px;
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     cursor: pointer;
//     background-color: var(--color-white);
//     mask-repeat: no-repeat;
//     mask-size: 30px 30px;
//     mask-position: center;
//     z-index: 11;
//     @screen md {
//       display: block;
//     }
//     @screen lg {
//       mask-size: 40px 40px;
//     }
//   }
//   .nextSlide {
//     right: 0px;
//     mask-image: url("~@/assets/images/chevron-right.svg?uri");
//     @screen lg {
//       right: 25px;
//     }
//   }
//   .prevSlide {
//     left: 0px;
//     mask-image: url("~@/assets/images/chevron-left.svg?uri");
//     @screen lg {
//       left: 25px;
//     }
//   }
// }

:deep(.swiper-slide) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: px(15);
  @screen md {
    padding: px(50);
  }
  @screen lg {
    padding: px(150);
  }

  .pagination {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    line-height: 0.9em;
    font-family: var(--font-primary);
    @screen md {
      top: 2rem;
      right: 2rem;
      font-size: 2rem;
    }
  }

  .image-text-container {
    width: auto;
    display: inline-block;
    position: relative;

    @screen md {
      height: 100%;
    }

   img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      margin: 0;
      max-height: 400px; // for mobile portrait pics
      @screen md {
        height: calc(100% - 6rem);
        max-height: 100%;
      }
    }
  }

  .caption {
    padding-top: 1rem;
    p {
      font-size: 1rem;
      @screen md {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
